@import '~antd/dist/antd.css';

html, body {
  width: 100vw;
  min-width: 350px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: #222;
  background: #fff;
  font-family: poppins, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.grecaptcha-badge {
  visibility: hidden;
}
